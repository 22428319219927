import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
import { NavLink as RouterLink } from "react-router-dom";
import logo0FROG from "../assets/logo0FROG.png";
import axios from "axios";
import { FaFileContract } from "react-icons/fa";

// Wrapper pour la section héro avec un fond animé
const HeroWrapper = styled.section`
  background: linear-gradient(135deg, #00a86b, #333333);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 80px 20px;
  margin-top: 60px; /* Ajout d'une marge pour éviter le chevauchement avec le header */
  position: relative;
  overflow: hidden; /* Empêche les débordements */

  &::before {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%; /* Augmente la largeur pour couvrir l'écran */
    height: 200%; /* Augmente la hauteur */
    background: radial-gradient(circle, rgba(255, 255, 255, 0.1), transparent);
    animation: rotateBG 15s linear infinite;
    z-index: -1; /* Place l'animation derrière le contenu */
  }

  @keyframes rotateBG {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @media (max-width: 768px) {
    padding: 50px 10px;
    margin-top: 50px; /* Ajustez pour les petits écrans si la hauteur du header est différente */
  }
`;


// Bandeau d'alerte
const AlertBanner = styled.div`
  background-color: #ff7f50;
  color: white;
  text-align: center;
  padding: 1rem;
  font-size: 1.1rem;
  font-weight: bold;
  border-radius: 10px;
  margin: 1rem 0; /* Réduisez les marges */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  animation: slideIn 1s ease-out;

  a {
    color: #ffffff;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      color: #00ff88;
    }
  }

  @keyframes slideIn {
    from {
      transform: translateY(-50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

const Navbar = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.85); /* Légèrement transparent pour mieux s'intégrer */
  z-index: 1000;
  box-sizing: border-box;

  /* Ajout pour éviter de chevaucher */
  height: 60px; /* Hauteur fixe du header */
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
  }
`;



// Bouton de menu hamburger
const HamburgerButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  z-index: 1100;
  position: fixed;
  top: 1rem;
  right: 1rem;

  @media (min-width: 768px) {
    display: none; /* Cache le bouton sur les écrans larges */
  }

  div {
    width: 100%;
    height: 4px;
    background-color: white;
    border-radius: 5px;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  /* État lorsque le menu est ouvert */
  ${({ isOpen }) =>
          isOpen &&
          `
    div:nth-child(1) {
      transform: rotate(45deg) translate(5px, 5px);
    }
    div:nth-child(2) {
      opacity: 0;
    }
    div:nth-child(3) {
      transform: rotate(-45deg) translate(5px, -5px);
    }
  `}
`;

// Container pour les liens de navigation
const NavLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  position: fixed;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? "0" : "-100%")}; /* Glisse le menu hors de l'écran lorsqu'il est fermé */
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  padding: 2rem;
  transition: left 0.3s ease; /* Animation fluide */

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: static;
    height: auto;
    width: auto;
    background-color: transparent;
    transition: none;
  }
`;


// Liens de navigation
const NavLink = styled(Link)`
  color: #ffd700; /* Couleur dorée pour le contraste */
  font-size: 1.5rem; /* Texte plus grand sur mobile */
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #00ff88; /* Vert vif au survol */
  }

  @media (min-width: 768px) {
    font-size: 1rem; /* Réduit la taille du texte sur PC */
    margin: 0 1rem;
  }
`;


// Lien vers une autre page utilisant react-router-dom
const RouterNavLink = styled(RouterLink)`
  color: #ffd700;
  font-size: 1.2rem;
  margin: 0 1rem;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #00a86b;
  }
`;

// Bouton de Connexion Wallet
const WalletButton = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: white;
  background-color: #ffd700;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  max-width: 300px; /* Augmenté pour les longues adresses */
  overflow: hidden; /* Empêche le débordement horizontal */
  text-overflow: ellipsis; /* Ajoute "..." pour tronquer si nécessaire */
  white-space: nowrap; /* Empêche le texte de passer à la ligne */
  box-sizing: border-box;

  &:hover {
    background-color: #00a86b;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
    padding: 0.4rem 0.8rem;
  }
`;

// Container pour le logo et le modal
// Ajout des styles pour le container du logo et du modal
const LogoModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 1rem;
  }
`;

const LogoImage = styled(motion.img)`
  width: 300px; /* Ajustez la taille pour qu'elle soit proportionnelle */
  height: auto;
  margin-bottom: 2rem;
  margin-top: 20px;
  position: relative;
  z-index: 10; /* S'assure qu'il est au-dessus de l'animation de fond */

  animation: float 3s ease-in-out infinite; /* Ajout de l'animation de flottement */

  @keyframes float {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
  }

  @media (max-width: 768px) {
    width: 200px;
    margin-bottom: 1rem;
  }
`;


const HeroTitle = styled(motion.h1)`
  font-size: 4rem;
  color: #FFD700;
  margin-bottom: 2rem;

  span {
    color: #00FF88;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
`;

const SubTitle = styled(motion.p)`
  font-size: 1.5rem;
  color: #FFD700;
  margin-bottom: 3rem;
  max-width: 800px;
  text-align: center;
  line-height: 1.6;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    max-width: 100%;
  }
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Permet d'enrouler les éléments si nécessaire */
  gap: 2rem;
  margin-top: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
  }
`;


const StatBox = styled.div`
  color: #FFD700;
  text-align: center;

  h3 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1.2rem;
    color: #FFFFFF;
  }

  @media (max-width: 768px) {
    h3 {
      font-size: 2rem;
    }

    p {
      font-size: 1rem;
    }
  }
`;

const ContractLinks = styled.div`
  margin-top: 3rem;
  text-align: center;
  color: #ffd700;

  @media (max-width: 768px) {
    margin-top: 2rem;
    font-size: 0.9rem;
  }

  a {
    color: #ffd700;
    text-decoration: none;
    margin: 0 5px;
    font-weight: bold;
    display: inline-block; /* Empêche les débordements horizontaux */
    word-wrap: break-word; /* Permet de découper les longues chaînes si nécessaire */

    &:hover {
      color: #00a86b;
    }
  }
`;


const HeroSection = () => {
    const [tokensSold, setTokensSold] = useState(); // Valeur par défaut
    const [isMenuOpen, setIsMenuOpen] = useState(false); // État pour le menu hamburger
    const toggleMenu = () => {
        setIsMenuOpen((prev) => !prev); // Bascule l’état du menu
    };
    const handleNavLinkClick = () => {
        setIsMenuOpen(false); // Ferme le menu après un clic
    };


    useEffect(() => {
        const fetchTokensSold = async () => {
            try {
                const response = await fetch('/api/presale-total'); // Créez une nouvelle route dans votre backend qui calcule la somme
                const data = await response.json();
                setTokensSold(data.total_tokens_sold);
            } catch (error) {
                console.error('Erreur lors de la récupération des tokens vendus:', error);
            }
        };

        fetchTokensSold();
    }, []);

    const [activeWallets, setActiveWallets] = useState(0);

    useEffect(() => {
        const fetchActiveWallets = async () => {
            try {
                const response = await fetch('/api/active-wallets');
                const data = await response.json();
                setActiveWallets(data.active_wallets); // Mettre à jour l'état avec le nombre de wallets actifs
            } catch (error) {
                console.error('Erreur lors de la récupération des wallets actifs:', error);
            }
        };

        fetchActiveWallets();
    }, []);


    const [walletAddress, setWalletAddress] = useState(null);

    // Fonction pour se connecter au portefeuille MetaMask
    const connectWallet = async () => {
        if (window.ethereum) {
            try {
                // Demande de permissions pour accéder au compte
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

                if (accounts.length > 0) {
                    const walletAddress = accounts[0];
                    setWalletAddress(walletAddress);
                    console.log("Wallet connecté : ", walletAddress);

                    // Enregistrement du wallet dans la base de données
                    await axios.post('/api/wallets', { walletAddress });
                    console.log("Wallet enregistré dans la base de données.");
                } else {
                    alert("Aucun compte détecté. Veuillez vérifier votre portefeuille.");
                }
            } catch (error) {
                // Gestion des erreurs
                if (error.code) {
                    console.error(`Erreur de connexion : [Code ${error.code}] - ${error.message}`);
                    alert(`Erreur de connexion : ${error.message}`);
                } else {
                    console.error("Erreur inattendue lors de la connexion au wallet : ", error);
                    alert("Erreur inattendue. Veuillez réessayer.");
                }
            }
        } else {
            // MetaMask non détecté
            const isMobile = /Mobi|Android/i.test(navigator.userAgent);
            if (isMobile) {
                // Redirection vers le navigateur intégré de MetaMask
                alert("MetaMask non détecté. Veuillez utiliser le navigateur intégré de MetaMask.");
                window.location.href = "https://metamask.app.link/dapp/ofrog.xyz";
                // Remplacez `yourwebsite.com` par votre URL.
            } else {
                alert("MetaMask non détecté. Veuillez installer MetaMask pour continuer.");
            }
        }
    };


    const disconnectWallet = () => {
        setWalletAddress(null);
        console.log("Wallet déconnecté");
    };

    const handleWalletButtonClick = () => {
        if (walletAddress) {
            disconnectWallet();
        } else {
            connectWallet();
        }
    };

    return (
        <HeroWrapper id="hero">
            <Navbar>
                {/* Bouton hamburger pour les petits écrans */}
                <HamburgerButton isOpen={isMenuOpen} onClick={toggleMenu}>
                    <div />
                    <div />
                    <div />
                </HamburgerButton>


                {/* Liens de navigation */}
                <NavLinkContainer isOpen={isMenuOpen}>
                    <NavLink to="hero" smooth={true} duration={800} offset={-80} onClick={handleNavLinkClick}>
                        Hero
                    </NavLink>
                    <NavLink to="tokenomics" smooth={true} duration={800} offset={-80} onClick={handleNavLinkClick}>
                        Tokenomics
                    </NavLink>
                    <NavLink to="roadmap" smooth={true} duration={800} offset={-80} onClick={handleNavLinkClick}>
                        Roadmap
                    </NavLink>
                    <NavLink to="faq" smooth={true} duration={800} offset={-80} onClick={handleNavLinkClick}>
                        FAQ
                    </NavLink>
                    <RouterNavLink to="/dashboard" onClick={handleNavLinkClick}>
                        Dashboard
                    </RouterNavLink>
                    <RouterNavLink to="/whitepaper" smooth={true} duration={800} offset={-80} onClick={handleNavLinkClick}>
                        White Paper
                    </RouterNavLink>
                </NavLinkContainer>

                {/* Bouton pour connecter ou déconnecter le wallet */}
                <WalletButton onClick={handleWalletButtonClick}>
                    {walletAddress
                        ? `Déconnecter Wallet (${walletAddress.slice(0, 6)}...${walletAddress.slice(-4)})`
                        : "Connecter Wallet"}
                </WalletButton>
            </Navbar>

            {/* Reste de la section */}
            <LogoModalContainer>
                <LogoImage
                    src={logo0FROG}
                    alt="FrogoCoin Logo"
                    initial={{ opacity: 0, y: -100 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                />
            </LogoModalContainer>

            {activeWallets < 1000 && (
                <AlertBanner>
                    🚨 <a onClick={connectWallet}>Connectez votre wallet</a> maintenant pour rejoindre les 1000 premiers whitelistés et bénéficier de la prévente exclusive !
                </AlertBanner>
            )}

            <HeroTitle
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.2 }}
            >
                Investissez dans <span>FrogoCoin</span> et sautez vers la Lune !
            </HeroTitle>
            <SubTitle
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1.2, duration: 1 }}
            >
                Rejoignez la révolution des memecoins avec un token à fort potentiel. L’avenir de la finance décentralisée est ici, à portée de main !
            </SubTitle>
            <StatsContainer>
                <StatBox>
                    <h3>{tokensSold}</h3>
                    <p>Tokens vendus</p>
                </StatBox>
                <StatBox>
                    <h3>{activeWallets}</h3>
                    <p>Wallets Whitelistés</p>
                </StatBox>
                <StatBox>
                    <h3>50%</h3>
                    <p>Liquidité verrouillée</p>
                </StatBox>
            </StatsContainer>
            <ContractLinks>
                <p>
                    <FaFileContract /> Consultez les contrats sur BscScan :
                </p>
                <a
                    href="https://bscscan.com/address/0x24d506848912EB60bC01434693D1Cd023F0C33cb"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Contrat 0FROG
                </a>
                <a
                    href="https://bscscan.com/address/0xE6DCB7110F966DEDBb3df7Cb25C665a327ea1323"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Contrat de Verrouillage (Lock)
                </a>
            </ContractLinks>
        </HeroWrapper>
    );
};

export default HeroSection;
