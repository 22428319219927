import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useLocation } from "react-router-dom";

// Conteneur principal du profil
const ProfileContainer = styled.div`
  margin: 20px auto;
  padding: 30px;
  max-width: 800px;
  background: linear-gradient(135deg, #1e293b, #34495e);
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  color: #ecf0f1;
  text-align: center;

  @media (max-width: 768px) {
    padding: 20px;
    margin: 10px;
  }
`;

const ProfileHeader = styled.h2`
  font-size: 1.8rem;
  font-weight: bold;
  color: #FFD700;
  margin-bottom: 20px;
`;

const WalletInfo = styled.p`
  font-size: 1rem;
  margin: 5px 0;
  color: #E2E8F0;
  word-wrap: break-word;
`;

const ReferralSection = styled.div`
  margin-top: 20px;
  background: #334155;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
`;

const ReferralText = styled.p`
  font-size: 0.95rem;
  color: #94A3B8;
  margin: 5px 0;
`;

const ReferralLink = styled.pre`
  background-color: #1E293B;
  padding: 10px;
  border-radius: 8px;
  color: #FFD700;
  font-size: 0.85rem;
  margin: 10px 0;
  overflow-x: auto;
  text-align: left;
`;

const CopyButton = styled.button`
  margin-top: 10px;
  background-color: #FFD700;
  color: #1E293B;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #00A86B;
    color: #FFFFFF;
    transform: scale(1.05);
  }
`;

const StatusText = styled.p`
  font-size: 1rem;
  font-weight: bold;
  margin-top: 10px;
  color: ${(props) => (props.status === "Whitelisted" ? "#00FF88" : "#FFD700")};
`;

const InviteHeader = styled.h3`
  font-size: 1.2rem;
  font-weight: bold;
  color: #FFD700;
  margin: 15px 0 10px;
`;

const InviteDescription = styled.p`
  font-size: 0.95rem;
  color: #94A3B8;
  margin-bottom: 15px;
`;

const ErrorMessage = styled.p`
  color: #FF6347;
  font-weight: bold;
  font-size: 0.95rem;
`;

const ProfileSection = () => {
    const [walletAddress, setWalletAddress] = useState(null); // Adresse de l'utilisateur connecté
    const [referrals, setReferrals] = useState([]); // Liste des parrainages
    const [whitelistStatus, setWhitelistStatus] = useState(null); // Statut whitelist
    const [referrerAddress, setReferrerAddress] = useState(null); // Adresse du parrain
    const [error, setError] = useState(null); // Gestion des erreurs
    const location = useLocation(); // Récupérer les paramètres de l'URL

    // Récupérer le paramètre `ref` depuis l'URL
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const ref = params.get("ref"); // Lire ?ref=0x123...
        if (ref) {
            setReferrerAddress(ref); // Pré-remplir l'adresse du parrain
        }
    }, [location.search]);

    // Connecter le wallet et gérer le parrainage
    const connectWallet = async () => {
        if (window.ethereum) {
            try {
                const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
                const wallet = accounts[0];
                setWalletAddress(wallet);

                // Enregistrer le wallet et le parrainage
                await axios.post("/api/wallets", {
                    walletAddress: wallet,
                    referrerAddress: referrerAddress || null, // Ajouter le parrain si présent
                });

                // Charger les données utilisateur après connexion
                fetchUserData(wallet);
            } catch (error) {
                console.error("Erreur lors de la connexion au wallet :", error);
                setError("Impossible de connecter votre wallet.");
            }
        } else {
            alert("MetaMask non détecté. Veuillez installer MetaMask.");
        }
    };

    // Récupérer les données utilisateur
    const fetchUserData = async (wallet) => {
        try {
            // Récupérer les parrainages
            const referralsResponse = await axios.get(`/api/referrals/${wallet}`);
            setReferrals(referralsResponse.data);
        } catch (error) {
            if (error.response?.status === 404) {
                // Si aucun parrainage trouvé, mettre referrals à une liste vide
                setReferrals([]);
            } else {
                console.error("Erreur lors de la récupération des parrainages :", error);
            }
        }

        try {
            // Vérifier le statut whitelist
            const whitelistResponse = await axios.get(`/api/whitelisted/${wallet}`);
            setWhitelistStatus(whitelistResponse.data.isWhitelisted ? "Whitelisted" : "Waiting List");
        } catch (error) {
            console.error("Erreur lors de la récupération du statut whitelist :", error);
            setWhitelistStatus("Erreur");
        }
    };


    return (
        <ProfileContainer>
            {walletAddress ? (
                <>
                    <ProfileHeader>Bienvenue !</ProfileHeader>
                    <WalletInfo>
                        <strong>Adresse Wallet :</strong> {walletAddress}
                    </WalletInfo>
                    <StatusText status={whitelistStatus}>
                        <strong>Statut :</strong> {whitelistStatus || "Chargement..."}
                    </StatusText>
                    <ReferralSection>
                        <InviteHeader>Invitez vos amis</InviteHeader>
                        <InviteDescription>Partagez ce lien :</InviteDescription>
                        <ReferralLink>{`https://ofrog.xyz?ref=${walletAddress}`}</ReferralLink>
                        <CopyButton onClick={() => navigator.clipboard.writeText(`https://ofrog.xyz?ref=${walletAddress}`)}>
                            Copier le lien
                        </CopyButton>
                    </ReferralSection>

                    {/* Ajout de la section parrainages */}
                    <ReferralSection>
                        <h3>Parrainages</h3>
                        {referrals.length > 0 ? (
                            <ul>
                                {referrals.map((ref, index) => (
                                    <li key={index}>
                                        <strong>Wallet :</strong> {ref.referred_wallet} <br />
                                        <small>Ajouté le : {new Date(ref.created_at).toLocaleDateString()}</small>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>Aucun parrainage enregistré pour le moment.</p>
                        )}
                    </ReferralSection>
                </>
            ) : (
                <>
                    {referrerAddress && (
                        <StatusText>
                            <strong>Parrain détecté :</strong> {referrerAddress}
                        </StatusText>
                    )}
                    {error && <ErrorMessage>{error}</ErrorMessage>}
                    <CopyButton onClick={connectWallet}>Connecter mon wallet</CopyButton>
                </>
            )}
        </ProfileContainer>

    );
};

export default ProfileSection;
