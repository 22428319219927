import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { FaPiggyBank, FaChartPie, FaCogs, FaCrown } from "react-icons/fa"; // Importez l'icône pour le créateur

// Wrapper pour la section Tokenomics
const TokenomicsWrapper = styled.section`
  padding: 6rem 2rem;
  background-color: #1E1E1E;
  color: white;
  text-align: center;
  position: relative;

  @media (max-width: 768px) {
    padding: 4rem 1rem;
  }
`;

const Title = styled.h2`
  font-size: 3rem;
  color: #FFD700;
  margin-bottom: 3rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

// Conteneur du graphique circulaire
const PieChart = styled.div`
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: ${(props) =>
          `conic-gradient(
      ${props.active === 'presale' ? '#00A86B' : '#00FF88'} 0% 50%, 
      ${props.active === 'liquidity' ? '#FFD700' : '#FF4500'} 50% 75%, 
      ${props.active === 'development' ? '#FF6347' : '#FF00E8'} 75% 87.5%, 
      ${props.active === 'creator' ? '#800080' : '#FF00FF'} 87.5% 100%
    )`};
  position: relative;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.5);
  transform-style: preserve-3d;
  transform: rotateX(20deg) rotateY(15deg);
`;

// Cercle interne pour donner un effet design
const InnerCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 160px;
  height: 160px;
  background-color: #1E1E1E;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-size: 1.5rem;
`;

// Conteneur de détails en Grid pour disposer les éléments sur plusieurs lignes
const TokenDetailsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
`;

const TokenDetail = styled(motion.div)`
  background-color: #333;
  border-radius: 10px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05);  // Effet zoom lors du survol
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
  }
`;

const IconWrapper = styled(motion.div)`
  font-size: 2.5rem;
  color: ${(props) => props.color};
  margin-bottom: 1rem;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.2);  // Agrandissement lors du survol
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
  }
`;

const Tokenomics = () => {
    const [activeSection, setActiveSection] = useState(null);

    return (
        <TokenomicsWrapper id="tokenomics">
            <Title>Tokenomics</Title>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <PieChart active={activeSection} onMouseLeave={() => setActiveSection(null)}>
                    <InnerCircle>100%</InnerCircle>
                </PieChart>
            </div>

            {/* Conteneur des détails en Grid pour dispatch sur plusieurs lignes */}
            <TokenDetailsGrid>
                <TokenDetail onMouseEnter={() => setActiveSection("presale")}>
                    <IconWrapper color="#00A86B">
                        <FaPiggyBank />
                    </IconWrapper>
                    <h3>50% Prévente</h3>
                    <p>
                        <strong>50%</strong> des tokens sont réservés à la prévente, offrant une opportunité unique d'investir tôt dans FrogoCoin.
                    </p>
                </TokenDetail>

                <TokenDetail onMouseEnter={() => setActiveSection("liquidity")}>
                    <IconWrapper color="#FFD700">
                        <FaChartPie />
                    </IconWrapper>
                    <h3>25% Liquidité Verrouillée</h3>
                    <p>
                        <strong>25%</strong> des tokens sont alloués à la liquidité, assurant une stabilité de marché et réduisant les fluctuations soudaines.
                    </p>
                </TokenDetail>

                <TokenDetail onMouseEnter={() => setActiveSection("development")}>
                    <IconWrapper color="#FF6347">
                        <FaCogs />
                    </IconWrapper>
                    <h3>12.5% Développement et Récompenses</h3>
                    <p>
                        <strong>12.5%</strong> des tokens sont alloués au développement, pour améliorer le projet et récompenser la communauté.
                    </p>
                </TokenDetail>

                <TokenDetail onMouseEnter={() => setActiveSection("creator")}>
                    <IconWrapper color="#800080">
                        <FaCrown />
                    </IconWrapper>
                    <h3>12.5% Créateur</h3>
                    <p>
                        <strong>12.5%</strong> des tokens sont réservés au créateur et sont verrouillés pendant <strong>un an</strong> pour garantir un engagement à long terme.
                    </p>
                </TokenDetail>
            </TokenDetailsGrid>
        </TokenomicsWrapper>
    );
};

export default Tokenomics;
