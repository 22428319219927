import React from "react";
import styled from "styled-components";
import { FaFrog, FaRocket, FaLock, FaHandsHelping, FaFireAlt, FaPiggyBank, FaShieldAlt } from "react-icons/fa"; // Import de nouvelles icônes pertinentes

// Wrapper pour la section description
const DescriptionWrapper = styled.section`
  padding: 4rem 2rem;
  background-color: #2c2c2c;
  color: white;
  text-align: center;
`;

// Titre principal
const Title = styled.h2`
  font-size: 3rem;
  color: #ffd700;
  margin-bottom: 2rem;
`;

// Texte de description générale
const Text = styled.p`
  font-size: 1.3rem;
  color: #ffffff;
  max-width: 800px;
  margin: 0 auto 3rem;
  line-height: 1.7;
  span {
    color: #ffd700; /* Accentuation de certains termes */
    font-weight: bold;
  }
`;

// Conteneur pour les avantages
const BenefitsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
  margin-top: 2rem;
`;

// Carte pour chaque avantage
const BenefitCard = styled.div`
  background-color: #333333;
  padding: 2rem;
  border-radius: 10px;
  max-width: 300px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-10px); // Animation au survol
  }
`;

// Icône pour chaque carte
const BenefitIcon = styled.div`
  font-size: 3rem;
  color: #ffd700;
  margin-bottom: 1rem;
`;

// Titre de chaque avantage
const BenefitTitle = styled.h3`
  font-size: 1.5rem;
  color: #ffd700;
  margin-bottom: 1rem;
`;

// Description de chaque avantage
const BenefitDescription = styled.p`
  font-size: 1.1rem;
  color: #00a86b;
`;

const benefits = [
    {
        icon: <FaFrog />,
        title: "Divertissement & Dynamisme",
        description:
            "FrogoCoin n’est pas qu’un investissement, c’est une expérience amusante et engageante où la communauté propulse le projet vers de nouveaux horizons.",
    },
    {
        icon: <FaRocket />,
        title: "Croissance Explosive",
        description:
            "Propulsé par des tokenomics solides, FrogoCoin est en route pour des sommets. Préparez-vous à décoller avec nous !",
    },
    {
        icon: <FaLock />,
        title: "Sécurité & Transparence",
        description:
            "Avec 50% de la liquidité verrouillée, nous garantissons sécurité, confiance et stabilité pour vos investissements.",
    },
    {
        icon: <FaHandsHelping />,
        title: "Communauté Solidaire",
        description:
            "Rejoignez une communauté soudée et passionnée, prête à soutenir chaque membre dans cette aventure passionnante.",
    },
    {
        icon: <FaFireAlt />,
        title: "Mécanisme de Burn",
        description:
            "Pour augmenter la rareté et la valeur, FrogoCoin dispose d'un mécanisme de burn, permettant de détruire les tokens non vendus après la prévente.",
    },
    {
        icon: <FaPiggyBank />,
        title: "Staking Récompensé",
        description:
            "Les détenteurs de FrogoCoin peuvent staker leurs tokens pour obtenir des récompenses jusqu’à 25%, favorisant une fidélité à long terme.",
    },
    {
        icon: <FaLock />,
        title: "Verrouillage des Tokens du Créateur",
        description:
            "Les tokens du créateur sont verrouillés pendant un an, garantissant ainsi une absence de vente soudaine par le créateur. Cela montre un engagement à long terme et une volonté de soutenir le projet de manière durable.",
    },
    {
        icon: <FaShieldAlt />,
        title: "Contrat de Verrouillage (Lock)",
        description:
            "Le contrat de verrouillage ('Lock') est un mécanisme transparent qui garantit que les fonds essentiels du projet sont sécurisés pendant un an. Cela renforce la transparence et rassure les investisseurs sur la stabilité de FrogoCoin.",
    },
];

const Description = () => {
    return (
        <DescriptionWrapper>
            <Title>Pourquoi choisir FrogoCoin ?</Title>
            <Text>
                Rejoignez <span>FrogoCoin</span> et plongez dans l'avenir de la{" "}
                <span>crypto dynamique</span> ! <br />
                Ce n'est pas juste un autre meme coin – c'est une chance <span>unique</span> de participer à
                un projet ambitieux. Avec une communauté passionnée et des avantages
                inégalés, <span>chaque investisseur</span> devient une partie intégrante de la
                révolution <span>FrogoCoin</span>. Prêt à vous envoler vers la Lune ?
            </Text>

            {/* Section des avantages */}
            <BenefitsContainer>
                {benefits.map((benefit, index) => (
                    <BenefitCard key={index}>
                        <BenefitIcon>{benefit.icon}</BenefitIcon>
                        <BenefitTitle>{benefit.title}</BenefitTitle>
                        <BenefitDescription>{benefit.description}</BenefitDescription>
                    </BenefitCard>
                ))}
            </BenefitsContainer>
        </DescriptionWrapper>
    );
};

export default Description;
