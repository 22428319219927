import React from 'react';
import styled from 'styled-components';
import { FaTwitter, FaTelegram, FaDiscord, FaLock, FaFileContract } from 'react-icons/fa';

const FooterWrapper = styled.footer`
  background-color: #333333;
  color: white;
  padding: 3rem 1rem;
  text-align: center;
  border-top: 1px solid #FFD700;
`;

const SocialIcons = styled.div`
  margin-bottom: 1.5rem;

  a {
    margin: 0 15px;
    color: #FFD700;
    font-size: 2rem;
    text-decoration: none;

    &:hover {
      color: #00A86B;
    }
  }
`;

const ContractLinks = styled.div`
  margin: 1.5rem 0;
  font-size: 1.2rem;
  color: #FFD700;

  a {
    margin: 0 10px;
    color: #FFD700;
    text-decoration: none;
    font-weight: bold;

    &:hover {
      color: #00A86B;
    }
  }
`;

const Copyright = styled.p`
  font-size: 1rem;
  margin-top: 2rem;
  color: #FFD700;
`;

const Footer = () => {
    return (
        <FooterWrapper>
            <SocialIcons>
                <a href="https://x.com/0frogOwner" target="_blank" rel="noopener noreferrer">
                    <FaTwitter />
                </a>
                <a href="https://discord.gg/gw4R9wYf" target="_blank" rel="noopener noreferrer">
                    <FaDiscord />
                </a>
            </SocialIcons>
            <ContractLinks>
                <p>
                    <FaFileContract /> Consultez les contrats sur BscScan :
                </p>
                <a href="https://bscscan.com/address/0x24d506848912EB60bC01434693D1Cd023F0C33cb" target="_blank" rel="noopener noreferrer">
                    Contrat 0FROG
                </a>
                <a href="https://bscscan.com/address/0xE6DCB7110F966DEDBb3df7Cb25C665a327ea1323" target="_blank" rel="noopener noreferrer">
                    Contrat de Verrouillage (Lock)
                </a>
            </ContractLinks>
            <Copyright>© 2024 FrogoCoin. Tous droits réservés.</Copyright>
        </FooterWrapper>
    );
};

export default Footer;