import React, { useState } from "react";
import styled from "styled-components";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

// Wrapper pour la section FAQ
const FAQWrapper = styled.section`
  padding: 6rem 2rem;
  background-color: #1E1E1E;
  color: white;
  text-align: center;
`;

// Titre de la section FAQ
const Title = styled.h2`
  font-size: 3rem;
  color: #FFD700;
  margin-bottom: 3rem;
`;

// Conteneur des questions et réponses
const FAQContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

// Wrapper pour chaque question
const QuestionWrapper = styled.div`
  background-color: #2A2A2A;
  margin: 1rem 0;
  padding: 1.5rem;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #333333;
  }
`;

// Titre de chaque question
const QuestionTitle = styled.h3`
  font-size: 1.5rem;
  color: #FFD700;
`;

// Réponse
const Answer = styled.div`
  overflow: hidden;
  max-height: ${(props) => (props.$isOpen ? "500px" : "0")};
  transition: max-height 0.5s ease;
  background-color: #333333;
  padding: ${(props) => (props.$isOpen ? "1rem" : "0")};
  border-radius: 5px;
  opacity: ${(props) => (props.$isOpen ? "1" : "0")};
  transition: opacity 0.3s ease, max-height 0.5s ease;
`;

// Texte des réponses
const AnswerText = styled.p`
  font-size: 1rem;
  color: #CCCCCC;  /* Couleur du texte des réponses */
  margin: 0;
`;

// Chevron icons with animation
const ChevronIcon = styled.div`
  transition: transform 0.3s ease;
  transform: ${(props) => (props.$isOpen ? "rotate(180deg)" : "rotate(0)")};
`;

const FAQ = () => {
    const [openQuestion, setOpenQuestion] = useState(null);

    const toggleQuestion = (index) => {
        setOpenQuestion(openQuestion === index ? null : index);
    };

    return (
        <FAQWrapper id="faq">
            <Title>Questions Fréquemment Posées</Title>
            <FAQContainer>
                {/* Question 1 */}
                <QuestionWrapper onClick={() => toggleQuestion(1)}>
                    <QuestionTitle>Comment acheter FrogoCoin ?</QuestionTitle>
                    <ChevronIcon $isOpen={openQuestion === 1}>
                        {openQuestion === 1 ? <FaChevronUp /> : <FaChevronDown />}
                    </ChevronIcon>
                </QuestionWrapper>
                <Answer $isOpen={openQuestion === 1}>
                    <AnswerText>
                        FrogoCoin peut être acheté sur PancakeSwap. Connectez votre wallet (comme MetaMask), échangez vos BNB contre FrogoCoin, et bénéficiez de la liquidité verrouillée pour garantir une stabilité des prix et une sécurité accrue.
                    </AnswerText>
                </Answer>

                {/* Question 2 */}
                <QuestionWrapper onClick={() => toggleQuestion(2)}>
                    <QuestionTitle>Qu'est-ce qui rend FrogoCoin unique ?</QuestionTitle>
                    <ChevronIcon $isOpen={openQuestion === 2}>
                        {openQuestion === 2 ? <FaChevronUp /> : <FaChevronDown />}
                    </ChevronIcon>
                </QuestionWrapper>
                <Answer $isOpen={openQuestion === 2}>
                    <AnswerText>
                        FrogoCoin se distingue par une combinaison de sécurité, transparence, et communauté. Avec 50% de la liquidité verrouillée, un mécanisme de burn pour augmenter la rareté, des airdrops réguliers pour récompenser les détenteurs, et des tokens du créateur verrouillés pendant un an, FrogoCoin vise à garantir une croissance durable et à générer de la confiance pour les investisseurs.
                    </AnswerText>
                </Answer>

                {/* Question 3 */}
                <QuestionWrapper onClick={() => toggleQuestion(3)}>
                    <QuestionTitle>Quelle est la répartition des tokens de FrogoCoin ?</QuestionTitle>
                    <ChevronIcon $isOpen={openQuestion === 3}>
                        {openQuestion === 3 ? <FaChevronUp /> : <FaChevronDown />}
                    </ChevronIcon>
                </QuestionWrapper>
                <Answer $isOpen={openQuestion === 3}>
                    <AnswerText>
                        La répartition des tokens est la suivante : 50% pour la prévente, 25% sont verrouillés pour la liquidité, 12.5% pour le développement et les récompenses, et 12.5% pour le créateur (verrouillés pendant un an). Cela assure une utilisation équilibrée et transparente des fonds.
                    </AnswerText>
                </Answer>

                {/* Question 4 */}
                <QuestionWrapper onClick={() => toggleQuestion(4)}>
                    <QuestionTitle>Comment fonctionne le contrat de verrouillage ?</QuestionTitle>
                    <ChevronIcon $isOpen={openQuestion === 4}>
                        {openQuestion === 4 ? <FaChevronUp /> : <FaChevronDown />}
                    </ChevronIcon>
                </QuestionWrapper>
                <Answer $isOpen={openQuestion === 4}>
                    <AnswerText>
                        Le contrat de verrouillage garantit que les tokens du créateur et les fonds de liquidité sont sécurisés pendant une période déterminée. Les tokens du créateur sont verrouillés pendant un an, ce qui assure un engagement à long terme. Vous pouvez consulter le contrat sur BscScan pour une transparence totale.
                    </AnswerText>
                </Answer>

                {/* Question 5 */}
                <QuestionWrapper onClick={() => toggleQuestion(5)}>
                    <QuestionTitle>Quels sont les avantages d'investir dans FrogoCoin ?</QuestionTitle>
                    <ChevronIcon $isOpen={openQuestion === 5}>
                        {openQuestion === 5 ? <FaChevronUp /> : <FaChevronDown />}
                    </ChevronIcon>
                </QuestionWrapper>
                <Answer $isOpen={openQuestion === 5}>
                    <AnswerText>
                        FrogoCoin offre des avantages tels que : une liquidité verrouillée pour garantir la stabilité des prix, un mécanisme de burn pour réduire l'offre, un marketing proactif, des airdrops réguliers, et un verrouillage des tokens du créateur. Cela assure la transparence et la confiance des investisseurs, tout en favorisant la croissance du projet.
                    </AnswerText>
                </Answer>

                {/* Question 6 */}
                <QuestionWrapper onClick={() => toggleQuestion(6)}>
                    <QuestionTitle>Comment fonctionne le système d'airdrop ?</QuestionTitle>
                    <ChevronIcon $isOpen={openQuestion === 6}>
                        {openQuestion === 6 ? <FaChevronUp /> : <FaChevronDown />}
                    </ChevronIcon>
                </QuestionWrapper>
                <Answer $isOpen={openQuestion === 6}>
                    <AnswerText>
                        10% des tokens sont réservés aux airdrops pour récompenser les investisseurs actifs et les premiers utilisateurs. Pour participer, connectez votre wallet sur notre site 0frog.xyz et conservez vos tokens FrogoCoin. Vous recevrez automatiquement des tokens supplémentaires en fonction de votre activité et de votre soutien.
                    </AnswerText>
                </Answer>

                {/* Question 7 */}
                <QuestionWrapper onClick={() => toggleQuestion(7)}>
                    <QuestionTitle>Quand la prévente de FrogoCoin se termine-t-elle ?</QuestionTitle>
                    <ChevronIcon $isOpen={openQuestion === 7}>
                        {openQuestion === 7 ? <FaChevronUp /> : <FaChevronDown />}
                    </ChevronIcon>
                </QuestionWrapper>
                <Answer $isOpen={openQuestion === 7}>
                    <AnswerText>
                        La prévente de FrogoCoin dure 30 jours à partir de son lancement. Les premiers acheteurs bénéficient de réductions spéciales. Une fois la prévente terminée, FrogoCoin sera listé sur PancakeSwap, et la liquidité collectée sera verrouillée pour renforcer la stabilité.
                    </AnswerText>
                </Answer>
            </FAQContainer>
        </FAQWrapper>
    );
};

export default FAQ;
