import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import HeroSection from './components/HeroSection';
import Description from './components/Description';
import Tokenomics from './components/Tokenomics';
import Roadmap from './components/Roadmap';
import FAQ from './components/FAQ';
import Footer from "./components/Footer";
import Dashboard from './pages/Dashboard'; // Assurez-vous que la page Dashboard existe
import Whitepaper from './components/Whitepaper';
import GlobalStyle from "./GlobalStyle";
import '@fortawesome/fontawesome-free/css/all.min.css';
import { WalletProvider } from './context/WalletContext'; // Importation du WalletProvider

// Gestion de la redirection si un lien contient un paramètre `ref`
const RedirectHandler = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const refParam = queryParams.get('ref');

        if (refParam) {
            navigate(`/dashboard?ref=${refParam}`);
        }
    }, [navigate]);

    return null; // Pas besoin de retour visuel
};

function App() {
    return (
        <WalletProvider> {/* Envelopper l'application avec WalletProvider */}
            <Router>
                {/* Gestion des styles globaux */}
                <GlobalStyle />

                {/* Composant pour gérer la redirection */}
                <RedirectHandler />

                <Routes>
                    {/* Route principale : Page d'accueil */}
                    <Route path="/" element={
                        <>
                            <HeroSection />
                            <Description />
                            <Tokenomics />
                            <Roadmap />
                            <FAQ />
                            <Footer />
                        </>
                    } />

                    {/* Route pour le dashboard */}
                    <Route path="/dashboard" element={<Dashboard />} />

                    {/* Route pour le whitepaper */}
                    <Route path="/whitepaper" element={<Whitepaper />} />

                    {/* Route fallback pour les pages inexistantes */}
                    <Route path="*" element={
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100vh',
                            textAlign: 'center'
                        }}>
                            <h1>404</h1>
                            <p>Page non trouvée. Retournez à la <a href="/">page d'accueil</a>.</p>
                        </div>
                    } />
                </Routes>
            </Router>
        </WalletProvider>
    );
}

export default App;
