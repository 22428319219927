import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import StatsChart from '../components/StatsChart';
import logo0FROG from '../assets/logo0FROG.png';
import Alert from '../components/modale/alert_inactive';
import { ethers } from 'ethers';
import ProfileSection from '../components/ProfileSection';


// Styles pour le conteneur principal
const DashboardContainer = styled.div`
  padding: 40px;
  background: linear-gradient(135deg, #1e8449, #2c3e50); /* Couleurs légèrement adoucies */
  min-height: 100vh;
  color: white;
  text-align: center;
  position: relative;
`;

// Style de la barre de progression
const ProgressBarContainer = styled.div`
  background-color: #34495e;
  border-radius: 10px;
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  padding: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  color: #ecf0f1;
`;

const ProgressWrapper = styled.div`
  background-color: #2c3e50;
  border-radius: 10px;
  overflow: hidden;
  height: 25px;
`;

const Progress = styled.div`
  height: 100%;
  background-color: #00A86B;
  transition: width 1s ease-in-out;
  width: ${(props) => props.width}%;
`;

const ProgressText = styled.p`
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
`;

// Composant pour la carte d'achat de tokens
const PurchaseCard = styled.div`
  background-color: #34495e;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  padding: 20px;
  margin-bottom: 40px;
  color: #FFD700;
  text-align: center;
`;

const PurchaseInput = styled.input`
  padding: 10px;
  margin-right: 10px;
  width: 200px;
  border-radius: 5px;
  border: none;
  font-size: 1rem;
`;

const PurchaseButton = styled.button`
  padding: 10px 20px;
  font-size: 1.2rem;
  color: white;
  background-color: #FFD700;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #00A86B;
  }
`;

// Autres définitions de style
const StatsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 40px;
`;

const StatCard = styled.div`
  background-color: #34495e;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  padding: 20px;
  width: 250px;
  height: 150px;
  color: #FFD700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const StatNumber = styled.h2`
  color: #FFD700;
  font-size: 48px;
  margin: 0;
`;

const StatLabel = styled.p`
  font-size: 18px;
  color: #ecf0f1;
  margin: 5px 0 0;
`;

const LogoImage = styled.img`
  width: 150px;
  height: auto;
  margin: 0 20px;
  filter: drop-shadow(0 8px 15px rgba(0, 0, 0, 0.5));
`;

const TablesSection = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
`;

const TableContainer = styled.div`
  flex: 1;
  background-color: rgba(30, 26, 26, 0.1);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Table = styled.table`
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  background-color: #34495e;
  color: white;
  padding: 10px;
  text-align: center;
  border-bottom: 2px solid #FFD700;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const TableCell = styled.td`
  padding: 10px;
  text-align: left;
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const NoDataMessage = styled.div`
  text-align: center;
  padding: 20px;
  color: #7f8c8d;
`;

const TransactionsTableContainer = styled.div`
  flex: 1;
  background-color: rgba(30, 26, 26, 0.1);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const TransactionsTable = styled.table`
  width: 100%;
  max-width: 80%;
  border-collapse: collapse;
`;

const TransactionsTableHeader = styled.th`
  background-color: #34495e;
  color: white;
  padding: 10px;
  text-align: center;
  border-bottom: 2px solid #FFD700;
`;

const TransactionsTableRow = styled.tr`
  &:nth-child(even) {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const TransactionsTableCell = styled.td`
  padding: 10px;
  text-align: center;
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const NoTransactionsMessage = styled.div`
  text-align: center;
  padding: 20px;
  color: #7f8c8d;
`;

const ChartSection = styled.div`
  margin: 40px 0;
  background-color: rgba(19, 17, 17, 0.1);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  color: #ecf0f1;
`;

const ReturnButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #FFD700;
  color: #333333;
  border: none;
  border-radius: 5px;
  padding: 8px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #00A86B;
    color: white;
  }
`;

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3005';

const Dashboard = () => {
    const [stats, setStats] = useState({
        totalTokensSold: 0,
        totalParticipants: 0,
        transactions: [],
    });

    const [whitelisted, setWhitelisted] = useState([]);
    const [error, setError] = useState('');
    const [showAlert, setShowAlert] = useState(true);
    const [bnbAmount, setBnbAmount] = useState('');
    const [tokensToBuy, setTokensToBuy] = useState(0);
    const [isPresaleStarted, setIsPresaleStarted] = useState(false);
    const [whitelistedCount, setWhitelistedCount] = useState(0);
    const MAX_WHITELIST = 1000;
    const [referrerAddress, setReferrerAddress] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const [
                    statsResponse,
                    whitelistedResponse,
                    presaleResponse,
                    activeWalletsResponse,
                    presaleStatusResponse,
                ] = await Promise.all([
                    fetch('/api/dashboard-stats').then((res) => {
                        if (!res.ok) throw new Error('Erreur lors de la récupération des stats.');
                        return res.json();
                    }),
                    fetch('/api/whitelisted').then((res) => {
                        if (!res.ok) throw new Error('Erreur lors de la récupération des wallets whitelistés.');
                        return res.json();
                    }),
                    fetch('/api/presale').then((res) => {
                        if (!res.ok) throw new Error('Erreur lors de la récupération des transactions de la prévente.');
                        return res.json();
                    }),
                    fetch('/api/active-wallets').then((res) => {
                        if (!res.ok) throw new Error('Erreur lors de la récupération des wallets actifs.');
                        return res.json();
                    }),
                    fetch(`${API_URL}/api/presale-status`).then((res) => {
                        if (!res.ok) throw new Error('Erreur lors de la récupération du statut de la prévente.');
                        return res.json();
                    }),
                ]);

                const activeWallets = activeWalletsResponse.active_wallets || 0;

                setStats({
                    ...statsResponse,
                    totalParticipants: activeWallets, // Utilisation directe des wallets actifs
                    transactions: Array.isArray(presaleResponse) ? presaleResponse : [],
                });

                setWhitelisted(Array.isArray(whitelistedResponse.wallets) ? whitelistedResponse.wallets : []);

                // Mise à jour pour la barre de progression
                setWhitelistedCount(activeWallets);

                // Si la prévente est déjà démarrée ou s'il y a au moins 1000 wallets whitelistés, démarrez la prévente
                setIsPresaleStarted(presaleStatusResponse.isPresaleStarted || activeWallets >= 1000);
            } catch (error) {
                console.error('Une erreur s\'est produite :', error);
                setError('Une erreur s\'est produite. Veuillez réessayer.');
            }
        };

        fetchData();
    }, []);


    const progress = Math.min((whitelistedCount / MAX_WHITELIST) * 100, 100); // Calculer la progression

    const handleBnbChange = (e) => {
        const value = e.target.value;
        setBnbAmount(value);

        // Calculer le nombre de tokens en fonction du taux de prévente actuel
        if (value && !isNaN(value) && Number(value) > 0) {
            const currentRate = 10000000; // Remplacer par le taux réel actuel (par exemple 10_000_000)
            const tokens = Number(value) * currentRate;
            setTokensToBuy(tokens);
        } else {
            setTokensToBuy(0);
        }
    };


    const handleBuyTokens = async () => {
        if (!bnbAmount || isNaN(bnbAmount) || Number(bnbAmount) <= 0) {
            alert('Veuillez entrer un montant BNB valide.');
            return;
        }

        try {
            if (!window.ethereum) {
                alert('Veuillez installer MetaMask pour acheter des tokens.');
                return;
            }

            const provider = new ethers.BrowserProvider(window.ethereum); // Utilisation correcte de BrowserProvider
            const signer = await provider.getSigner(); // Récupération correcte du signer
            const transaction = await signer.sendTransaction({
                to: '0x24d506848912EB60bC01434693D1Cd023F0C33cb', // Remplacer par l'adresse du contrat
                value: ethers.parseEther(bnbAmount), // Utilisation correcte de ethers.parseEther
            });

            console.log('Transaction envoyée:', transaction);
            alert('Transaction réussie! Veuillez attendre la confirmation.');

            // Mettre à jour le backend avec les informations de la transaction
            await fetch('/api/presale', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    walletAddress: await signer.getAddress(),
                    amountPaid: bnbAmount,
                    tokensPurchased: 0, // À déterminer selon le taux actuel
                    transaction_hash: transaction.hash,
                }),
            });
        } catch (error) {
            console.error('Erreur lors de l\'achat de tokens:', error);
            alert('Erreur lors de l\'achat des tokens: ' + error.message);
        }
    };



    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    return (
        <DashboardContainer>
            <h1>Dashboard</h1>

            {/* Ajouter ici la section profil */}
            <ProfileSection />

            <StatsContainer>
                <StatCard>
                    <StatNumber>{stats.totalTokensSold}</StatNumber>
                    <StatLabel>Total de tokens vendus</StatLabel>
                </StatCard>

                <LogoImage src={logo0FROG} alt="FrogoCoin Logo" />

                <StatCard>
                    <StatNumber>{stats.totalParticipants || '0'}</StatNumber>
                    <StatLabel>Total de participants</StatLabel>
                </StatCard>
            </StatsContainer>

            {error && <div style={{ color: 'red' }}>{error}</div>}

            <ProgressBarContainer>
                <ProgressWrapper>
                    <Progress width={progress} />
                </ProgressWrapper>
                <ProgressText>
                    {whitelistedCount} / {MAX_WHITELIST} wallets actifs
                </ProgressText>
            </ProgressBarContainer>

            {isPresaleStarted && (
                <PurchaseCard>
                    <h3>Acheter des Tokens</h3>
                    <PurchaseInput
                        type="text"
                        value={bnbAmount}
                        onChange={handleBnbChange}
                        placeholder="Entrez le montant en BNB"
                    />
                    <PurchaseButton onClick={handleBuyTokens}>Acheter</PurchaseButton>
                    {bnbAmount && tokensToBuy > 0 && (
                        <p style={{ marginTop: '20px', color: '#FFD700' }}>
                            Vous recevrez environ {tokensToBuy.toLocaleString()} tokens.
                        </p>
                    )}
                </PurchaseCard>
            )}


            <TablesSection>
                {/* Transactions Table */}
                <TransactionsTableContainer>
                    <h3>Transactions</h3>
                    {stats.transactions.length > 0 ? (
                        <TransactionsTable>
                            <thead>
                            <tr>
                                <TransactionsTableHeader>Wallet</TransactionsTableHeader>
                                <TransactionsTableHeader>Tokens achetés</TransactionsTableHeader>
                                <TransactionsTableHeader>Montant payé</TransactionsTableHeader>
                                <TransactionsTableHeader>Hash de transaction</TransactionsTableHeader>
                                <TransactionsTableHeader>Date</TransactionsTableHeader>
                            </tr>
                            </thead>
                            <tbody>
                            {stats.transactions.map((transaction, index) => (
                                <TransactionsTableRow key={index}>
                                    <TransactionsTableCell>{transaction.wallet_address}</TransactionsTableCell>
                                    <TransactionsTableCell>{transaction.tokens_purchased}</TransactionsTableCell>
                                    <TransactionsTableCell>{transaction.amount_paid}</TransactionsTableCell>
                                    <TransactionsTableCell>{transaction.transaction_hash}</TransactionsTableCell>
                                    <TransactionsTableCell>{new Date(transaction.timestamp).toLocaleString()}</TransactionsTableCell>
                                </TransactionsTableRow>
                            ))}
                            </tbody>
                        </TransactionsTable>
                    ) : (
                        <NoTransactionsMessage>Aucune transaction disponible</NoTransactionsMessage>
                    )}
                </TransactionsTableContainer>

                {/* Whitelist Table */}
                <TableContainer>
                    <h3>Whitelistés</h3>
                    {whitelisted.length > 0 ? (
                        <Table>
                            <thead>
                            <tr>
                                <TableHeader>Wallet</TableHeader>
                                <TableHeader>Date d'ajout</TableHeader>
                            </tr>
                            </thead>
                            <tbody>
                            {whitelisted.map((entry, index) => (
                                <TableRow key={index}>
                                    <TableCell>{entry.wallet_address}</TableCell>
                                    <TableCell>{new Date(entry.added_at).toLocaleString()}</TableCell>
                                </TableRow>
                            ))}
                            </tbody>
                        </Table>
                    ) : (
                        <NoDataMessage>Aucun utilisateur whitelisté</NoDataMessage>
                    )}
                </TableContainer>
            </TablesSection>

            <ChartSection>
                <h3>Graphique des ventes de tokens</h3>
                <StatsChart data={stats.transactions} />
            </ChartSection>

            <Alert onClose={handleCloseAlert} show={showAlert} />

            <ReturnButton onClick={() => window.location.href = '/'}>
                Retour au site
            </ReturnButton>
        </DashboardContainer>
    );
};

export default Dashboard;
